import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductOption } from 'pages/ProductsPage/components/ProductOptions/types';
import { Product, ProductVariant } from 'services/product/types';
import { ProductState } from './types';

export const initialState: ProductState = {
  title: '',
  sku: '',
  body: '',
  price: 0,
  special_price: 0,
  product_type: 'simple',
  quantity: 0,
  variants: [],
  hasOptions: false,
  options: [],
  ean: ''
};

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProduct: (state: ProductState, { payload }: PayloadAction<Product & { options: ProductState['options']}>) => {
      state.title = payload.title;
      state.sku = '0001';
      state.body = payload.body;
      state.price = payload.price;
      state.special_price = payload.special_price;
      state.product_type = payload.product_type;
      state.quantity = 99;
      state.variants = payload.variants;
      state.options = payload.options;
    },
    changeTitle: (state: ProductState, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    changeDescription: (state: ProductState, action: PayloadAction<string>) => {
      state.body = action.payload;
    },
    changeOptions: (state: ProductState, action: PayloadAction<ProductOption[]>) => {
      state.options = action.payload;
    },
    changeVariants: (state: ProductState, action: PayloadAction<ProductVariant[]>) => {
      state.variants = action.payload;
    },
    changePrice: (state: ProductState, action: PayloadAction<Product['price']>) => {
      state.price = action.payload;
    },
    changeQuantity: (state: ProductState, action: PayloadAction<number>) => {
      state.quantity = action.payload;
    },
    changeSpecialPrice: (state: ProductState, action: PayloadAction<Product['special_price']>) => {
      state.special_price = action.payload;
    },
    changeHasOptions: (state: ProductState, action: PayloadAction<boolean>) => {
      state.hasOptions = action.payload;
    },
    resetProduct: () => initialState
  }
});

export const {
  setProduct,
  changeTitle,
  changeDescription,
  changePrice,
  changeSpecialPrice,
  changeQuantity,
  changeVariants,
  changeHasOptions,
  changeOptions,
  resetProduct
} = productSlice.actions;

export default productSlice.reducer;
