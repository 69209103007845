import { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectOption, triggerMenu } from 'features/drawer/drawerSlice';
import { RootState } from '@store/store';
import Drawer from 'components/Drawer';
import styles from './styles.module.scss';
import ProtectedComponent from '../ProtectedComponent';
import { Outlet, useLocation } from 'react-router-dom';
import { items } from 'consts/drawer';

const Layout = (): JSX.Element => {
  const location = useLocation();
  const status = useSelector((state: RootState) => state.drawer.status);
  const dispatch = useDispatch();

  const checkDrawer = () => {
    if (status) {
      dispatch(triggerMenu());
    }
  };

  useEffect(() => {
    if (status) {
      setTimeout(() => {
        dispatch(triggerMenu());
      }, 500);
    }

    const item = items.find((item) => item.path === location.pathname);

    if (item) 
      dispatch(selectOption({ index: item.id }));
  }, [location.pathname]);

  return (
    <ProtectedComponent>
      <div className={styles.layoutContainer}>
        <Drawer />
        <div className={styles.mainContainer} onClick={checkDrawer}>
          <Suspense fallback="Loading...">
            <Outlet />
          </Suspense>
        </div>
      </div>
    </ProtectedComponent>
  );
};

export default Layout;
