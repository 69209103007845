import { routes } from 'consts/routes';
import LoginPage from 'pages/LoginPage';
import { RouteObject } from 'react-router-dom';

const { LOGIN } = routes.root;

const rootRoutes = [
  {
    path: LOGIN,
    element: <LoginPage />,
  },
] as RouteObject[];

export { rootRoutes };
