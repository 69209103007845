import React, { forwardRef, ForwardRefRenderFunction, useState } from 'react';
import { StyleValue } from 'types/style';
import styles from './styles.module.scss';
import { InputProps } from './types';

const stringValidate = (text: StyleValue | undefined) => {
  if (text) {
    const cleanedValue = String(text).replace(/[^\d]/g, '');

    const numberValue = parseFloat(cleanedValue) / 100;

    const formattedValue = numberValue.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });

    return formattedValue;
  } else {
    const value = 0;
    
    const formattedValue = value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });
    
    return formattedValue;
  }
};

const InputBase: ForwardRefRenderFunction<
HTMLInputElement,
InputProps
> = ({
  type = 'text',
  className = '',
  id,
  name,
  text,
  placeholder = '',
  label,
  disabled,
  style,
  onChange,
  minLength,
  maxLength,
  pattern,
  defaultValue,
  ...props
}, register): JSX.Element => {
  const validatedValue = () => {
    let returnedValue;

    switch (type) {
    case 'number':
      returnedValue = Number(text);
      break;
    case 'money':
      returnedValue = stringValidate(text);
      break;
    
    default:
      returnedValue = text;
      break;
    }
    
    return returnedValue;
  };

  return (
    <>
      {label && (
        <label
          className={styles.label}
          htmlFor={id}
        >
          {label}
        </label>
      )}

      <input
        {...id && ({ id })}
        {...name && ({ name })}
        ref={register}
        className={`${styles.input} ${className} ${type === 'money' ? styles.money : ''}`}
        {...type && ({ type })}
        {...type === 'number' && ({ min: 0, max: 999 })}
        {...placeholder && ({ placeholder })}
        {...(onChange != null) && ({ onChange })}
        {...disabled && ({ disabled })}
        {...minLength && ({ minLength })}
        {...maxLength && ({ maxLength })}
        {...defaultValue && !text && ({ defaultValue })}
        {...!defaultValue && text !== undefined && ({ value: validatedValue() })}
        {...pattern && ({ pattern })}
        {...{ style }}
        {...props}
      />
    </>
  );
};

const Input = forwardRef(InputBase);

export default Input;
