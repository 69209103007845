import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { parseCookies } from 'nookies';
import { Product, ProductVariant } from './types';
import { ProductOption } from 'pages/ProductsPage/components/ProductOptions/types';

const makePrice = (price?: string | number | null) => {
  return Number(String(price).replace(/[^\d]/g, '')) / 100;
};

export const productApi = createApi({
  reducerPath: 'productApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_PUBLIC_HOST,
    prepareHeaders: (headers) => {
      const { 'nextauth.token': token } = parseCookies();

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getProducts: builder.query<Product[], void>({
      query: () => 'product'
    }),
    getProduct: builder.query<Product, string>({
      query: (id) => `product/${id}`,
      transformResponse(response: Product) {
        let price = 0;

        if (response.variants && response?.variants[0]) {
          price = Number(response.variants[0].price);
        }

        const newResponse = response;
        newResponse.price = price;

        const options: ProductOption[] = [];

        newResponse.variants?.sort((a, b) => a.position - b.position)?.map((variant) => {
          variant.options?.sort((a, b) => a.position - b.position)?.map((option) => {
            const hasOption = options.findIndex((filteredOption) => {
              return filteredOption.name === option.name;
            });

            if (hasOption < 0) {
              options.push({
                id: String(options.length + 1),
                isEditing: false,
                name: option.name,
                values: [
                  {
                    id: option.id,
                    value: option.value,
                    position: 0
                  }
                ]
              });
            } else {
              const hasOptionValue = options[hasOption].values.some(({value}) => value === option.value);
              if (!hasOptionValue)
                options[hasOption].values.push({
                  value: option.value,
                  id: option.id,
                  position: option.position
                });
            }
          });
        });

        const newProduct: Product & { options: ProductOption[], hasOptions: boolean } = {
          ...newResponse,
          options,
          hasOptions: !!options.length
        };

        return newProduct;
      }
    }),
    removeProduct: builder.mutation<null, string>({
      query: (id) => {
        return {
          url: `product/${id}`,
          method: 'DELETE',
          body: id
        };
      }
    }),
    createProduct: builder.mutation<Product, Product>({
      query: (product) => {
        const inventory = !product.inventory?.length || product.variants?.length ? undefined : product.inventory;

        const variants: Omit<ProductVariant, 'id' | 'enabled' | 'ean' | 'quantity'>[] = !product.variants?.length ? [] : [
          ...product.variants.map((variant) => {
            return {
              title: variant.title,
              sku: variant.sku,
              price: makePrice(variant.price),
              position: variant.position,
              options: [
                ...variant.options ?? []
              ],
              inventory: [
                {
                  inventory_id: "849fc2e4-21a0-4a68-93fd-a4a41638251e",
                  quantity: Number(variant.quantity),
                  status: true
                }
              ]
            };
          })
        ]; 
        
        const newProduct = {
          title: product.title,
          body: product.body,
          price: makePrice(product.price),
          special_price: makePrice(product.special_price),
          product_type: 'simple',
          enabled: false,
          variants,
          inventory
        };
        
        return {
          url: 'product',
          method: 'POST',
          body: newProduct
        };
      }
    }),
    uploadImages: builder.mutation<any, any>({
      query: (data) => {
        const formData = new FormData();

        for (const image of data.images) {
          formData.append('files', image);
        }
        
        return {
          url:  `product/${data.id}/upload-images`,
          method: 'POST',
          body: formData
        };
      },
    }),
    createShopifyProduct: builder.mutation<any, any>({
      query: (product) => {
        return {
          url: 'shopify/product/create',
          method: 'POST',
          body: product
        };
      }
    }),
    updateProduct: builder.mutation<Product, { product: Product, id: string }>({
      query: ({ id, product }) => {
        const newProduct = {
          id,
          title: product.title,
          body: product.body,
          price: makePrice(product.price),
          special_price: makePrice(product.special_price),
          product_type: 'simple',
          enabled: false,
          variants: [
            ...product.variants!.map((variant) => {
              return {
                id: variant.id ?? "0",
                product_id: id,
                title: variant.title,
                sku: variant.sku,
                price: makePrice(variant.price),
                position: variant.position,
                options: [
                  ...variant?.options?.map((option) => {
                    return {
                      ...option,
                      id: option.id ?? "0",
                    };
                  }) ?? []
                ],
                inventory: variant.inventory.length ? variant.inventory.map((inventory) => {
                  return {
                    ...inventory,
                    id: inventory.id ?? "849fc2e4-21a0-4a68-93fd-a4a41638251e",
                  };
                }) : [
                  {
                    id: 0,
                    inventory_id: "849fc2e4-21a0-4a68-93fd-a4a41638251e",
                    quantity: Number(variant.quantity),
                    status: true
                  }
                ]
              };
            })
          ],
        };

        return {
          url: `product/${id}`,
          method: 'PATCH',
          body: newProduct
        };
      }
    })
  })
});

export const { 
  useGetProductQuery, 
  useGetProductsQuery, 
  useCreateProductMutation,
  useUploadImagesMutation,
  useCreateShopifyProductMutation, 
  useRemoveProductMutation,
  useUpdateProductMutation
} = productApi;
