import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DrawerItem } from 'components/Drawer/types';
import { DrawerState } from './types';

const initialState: DrawerState = {
  status: false,
  selectedIndex: undefined,
  showSubmenu: false
};

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    triggerMenu: (state: DrawerState) => {
      state.status = !state.status;

      state.showSubmenu = false;
    },
    selectOption: (state: DrawerState, action: PayloadAction<{ index: DrawerItem['id'], userClicking?: boolean } | undefined>) => {
      const index = action.payload?.index;

      if (state.selectedIndex === index && action.payload?.userClicking) {
        state.showSubmenu = true;
        
        return;
      }

      state.selectedIndex = index;

      if (action.payload?.userClicking) {
        state.showSubmenu = true;
      }
    }
  }
});

export const { triggerMenu, selectOption } = drawerSlice.actions;

export default drawerSlice.reducer;
