import styles from './styles.module.scss';
import { ButtonProps } from './types';

const Button = ({
  children,
  status = 'default',
  size,
  className = '',
  elevation,
  onClick,
  align,
  type = 'button',
  disabled = false,
  isLoading = false,
  Component,
  style
}: ButtonProps): JSX.Element => {
  if (Component) {
    const CustomComponent = Component as keyof JSX.IntrinsicElements;
    
    return (
      <CustomComponent
        {...((onClick != null) && !isLoading && { onClick })}
        className={`
        ${styles.buttonContainer}
        ${styles['status-' + status]}
        ${styles['size-' + size]}
        ${styles['elevation-' + elevation]}
        ${styles['align-' + align]}
        ${disabled ? styles.disabled : ''}
        ${isLoading ? styles.isLoading : ''}
        ${className}
      `}
      >
        {children}
      </CustomComponent>
    );
  }
  
  return (
    <button
      type={type}
      {...((onClick != null) && !isLoading && { onClick })}
      style={style}
      className={`
        ${styles.buttonContainer}
        ${styles['status-' + status]}
        ${styles['size-' + size]}
        ${styles['elevation-' + elevation]}
        ${styles['align-' + align]}
        ${disabled ? styles.disabled : ''}
        ${isLoading ? styles.isLoading : ''}
        ${className}
      `}
      disabled={disabled}
    >
      {isLoading
        ? (
          <div className={styles.loading}>
            <div className={styles.loadingDot}></div>
            <div className={styles.loadingDot}></div>
            <div className={styles.loadingDot}></div>
          </div>
        )
        : children
      }
    </button>
  );
};

export default Button;
