import styles from './styles.module.scss';
import { CardProps } from './types';

const Card = ({
  id,
  children,
  style,
  className,
  radius,
  elevation,
  padding,
  position,
  width,
  display,
  justify,
  align,
  gap,
  mt,
  mb,
  mr,
  ml,
  pt,
  pb,
  pr,
  pl,
  background
}: CardProps): JSX.Element => {
  return (
    <div
      {...(id ? { id } : false)}
      className={`
        ${styles.cardContainer}
        ${className || ''}
        ${radius ? styles['radius-' + radius] : ''}
        ${elevation ? styles['elevation-' + elevation] : ''}
        ${display ? styles['display-' + display] : ''}
        ${align ? styles['align-' + align] : ''}
        ${justify ? styles['justify-' + justify] : ''}
        ${position ? styles['position-' + position] : ''}
        ${typeof padding === 'string' ? styles['padding-' + padding] : ''}
      `}
      style={{
        ...style,
        marginTop: mt,
        marginBottom: mb,
        marginLeft: ml,
        marginRight: mr,
        paddingTop: pt,
        paddingBottom: pb,
        paddingLeft: pl,
        paddingRight: pr,
        background,
        padding,
        width,
        gap
      }}>
      {children}
    </div>
  );
};

export default Card;
