import React from 'react';
import { Provider } from 'react-redux';
import { store } from './store/store';
import {
  RouterProvider
} from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import './styles/globals.css';

import { router } from 'router';

function App() {
  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;
