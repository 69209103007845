import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import drawerReducer from '../features/drawer/drawerSlice';
import productReducer from '../features/product/productSlice';
import userReducer from '../features/user/userSlice';
import { orderApi } from '../services/order';
import { customerApi } from '../services/customer';
import { productApi } from '../services/product';
import { optionApi } from '../services/productOptions';
import { authApi } from '../services/auth';

export const store = configureStore({
  reducer: {
    [orderApi.reducerPath]: orderApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [optionApi.reducerPath]: optionApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    drawer: drawerReducer,
    product: productReducer,
    user: userReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      orderApi.middleware,
      customerApi.middleware,
      productApi.middleware,
      optionApi.middleware,
      authApi.middleware
    )
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
