import Layout from "components/Layout";
import { createBrowserRouter } from "react-router-dom";
import { defaultRoutes } from "./DefaultRoutes";
import { rootRoutes } from "./RootRoutes";

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      ...defaultRoutes
    ]
  },
  ...rootRoutes
]);

export { router };
