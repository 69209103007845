const BASE_PATH = '';

const HOME = `${BASE_PATH}/`;
const LOGIN = `${BASE_PATH}/login`;
const ORDERS = `${BASE_PATH}/orders`; 
const ORDER_DETAIL = `${BASE_PATH}/order/:id`; 
const PRODUCTS = `${BASE_PATH}/products`; 
const PRODUCT_DETAIL = `${BASE_PATH}/product/:id`; 
const PRODUCT_CREATE = `${BASE_PATH}/products/create`; 
const INVENTORY = `${BASE_PATH}/inventory`; 
const CUSTOMERS = `${BASE_PATH}/customers`;
const CUSTOMER_DETAIL = `${BASE_PATH}/customer/:id`;

const defaults = {
  HOME,
  ORDERS,
  ORDER_DETAIL,
  PRODUCT_DETAIL,
  PRODUCT_CREATE,
  PRODUCTS,
  INVENTORY,
  CUSTOMERS,
  CUSTOMER_DETAIL
};

const root = {
  LOGIN,
};

export const routes = {
  defaults,
  root
};
