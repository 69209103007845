import { DrawerItem } from "components/Drawer/types";
import { AiOutlineHome, AiOutlineUser } from "react-icons/ai";
import { BsInboxes, BsHandbag } from "react-icons/bs";
import { PiPackageDuotone, PiShoppingCartSimpleLight } from "react-icons/pi";

import { routes } from "./routes"; 

const {
  HOME,
  ORDERS,
  CUSTOMERS,
  PRODUCTS,
} = routes.defaults;

export const items = [
  {
    id: 1,
    icon: <AiOutlineHome />,
    title: 'Home',
    path: HOME
  },
  {
    id: 2,
    icon: <PiShoppingCartSimpleLight />,
    title: 'Sales',
    children: [
      {
        title: 'Orders',
        icon: <BsHandbag />,
        path: ORDERS
      }
    ]
  },
  {
    id: 3,
    icon: <AiOutlineUser />,
    title: 'Customers',
    path: CUSTOMERS
  },
  {
    id: 4,
    icon: <BsInboxes />,
    title: 'Storages',
    children: [
      {
        icon: <PiPackageDuotone />,
        title: 'Products',
        path: PRODUCTS,
      },
    ]
  },
] as DrawerItem[];
