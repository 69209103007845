import styles from './styles.module.scss';
import {
  AiOutlineArrowRight,
} from 'react-icons/ai';
import { GoGear } from 'react-icons/go';
import { RootState } from '@store/store';
import { useDispatch, useSelector } from 'react-redux';
import { selectOption, triggerMenu } from 'features/drawer/drawerSlice';
import { memo, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { items } from 'consts/drawer';
import { DrawerItem } from './types';

const Drawer = (): JSX.Element => {
  const location = useLocation();
  const { status: active, selectedIndex, showSubmenu } = useSelector((state: RootState) => state.drawer);
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState<DrawerItem | undefined>();

  const selectedMenu = items.find((item) => item.id === selectedIndex);

  const handleTriggerMenu = () => {
    dispatch(triggerMenu());
  };

  const handleSelectOption = (item: DrawerItem, userClicking = false) => {
    if (!active && !item.path && item.children?.length) {
      dispatch(triggerMenu());
      dispatch(selectOption({ index: item.id, userClicking }));
      return;
    }
    
    if (active) {
      setSelectedItem(item);
      dispatch(selectOption({ index: item.id, userClicking }));
    }
  };

  useEffect(() => {
    if (!active) {
      const item = items.find((item) => item.path === location.pathname);

      if (item) {
        setSelectedItem(selectedMenu);
      }
    }
  }, [active]);

  
  useEffect(() => {
    setSelectedItem(selectedMenu);
  }, [selectedMenu]);

  return (
    <div className={`${styles.drawerContainer}`}>
      <div className={`${styles.menu} ${active && styles.active}`}>
        <div className={styles.condenseMenu} onClick={handleTriggerMenu}>
          <AiOutlineArrowRight />
          <span>Collapse menu</span>
        </div>

        <div className={styles.menuContainer}>
          <ul>
            {items.map((item) => {
              const isOpened = selectedItem === item;

              return (
                <li
                  key={item.title}
                  className={`${(isOpened) ? styles.active : ''}`}
                  onClick={() => handleSelectOption(item, true)}
                >
                  <div className={styles.itemHeader}>
                    <Link
                      to={item.path as string}
                      className={styles.itemName}
                      style={{ zIndex: !active ? 1 : 0}}
                    >
                      {item.icon}
                  
                      <span>{item.title}</span>
                    </Link>

                  </div>
                  <div className={styles.tooltip}>
                    <p>{item.title}</p>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>

        <div className={`${styles.configuration} ${styles.menuContainer}`}>
          <ul>
            <li>
              <div className={styles.itemHeader}>
                <Link to={"#"} className={styles.itemName}>
                  <GoGear />
                  <span>Settings</span>
                </Link>
                <div className={styles.tooltip}>
                  <p>Settings</p>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <p className={styles.copyright}>2022 - by Emanuel Correa</p>
      </div>

      {active && showSubmenu && !!selectedMenu?.children?.length && (
        <div className={styles.tempSubMenu}>
          <h2>{selectedMenu?.title}</h2>

          <ul>
            {selectedMenu?.children?.map((item) => {
              return (
                <li key={item.path}>
                  <Link 
                    to={item.path as string}
                  >
                    <div>
                      {item.icon}
                      <span>{item.title}</span>
                    </div>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default memo(Drawer);
