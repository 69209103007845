import Button from 'components/Button';
import Card from 'components/Card';
import Input from 'components/Input';
import { setUserInfo } from 'features/user/userSlice';
import { setCookie } from 'nookies';
import React, { FormEvent, useEffect, useState } from 'react';
import { AiFillUnlock } from 'react-icons/ai';
import { FaCheckCircle } from 'react-icons/fa';
import { IoMdRemoveCircle } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { cssTransition, toast, ToastContainer } from 'react-toastify';
import { useLoginMutation } from 'services/auth';
import styles from './styles.module.scss';
import 'animate.css';
import { useNavigate } from 'react-router-dom';
import { Page } from 'types/page';
import { User } from 'features/user/types';
import { routes } from 'consts/routes';

interface Data {
  data: {
    token: string;
    userInfo: User;
  }
}

const initialFields = {
  email: {
    value: '',
    error: false
  },
  password: {
    value: '',
    error: false
  }
};

const { HOME } = routes.defaults;

const LoginPage: Page = () => {
  const dispatch = useDispatch();
  const [fields, setFields] = useState(initialFields);
  const navigate = useNavigate();

  const [login, { isLoading, isSuccess, isError, reset }] = useLoginMutation();

  const flip = cssTransition({
    enter: 'animate__animated animate__flipInX',
    exit: 'animate__animated animate__flipOutX'
  });

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (isSuccess) return false;

    const { email: { value: email }, password: { value: password } } = fields;

    const validEmail = validateEmail(email);

    if (validEmail && !isLoading && !isSuccess && fields.password.value.length > 1) {
      const toastLoading = toast.loading('Trying to login...', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        draggable: true,
        progress: undefined,
        bodyClassName: styles.toastBody,
        transition: flip
      });

      const data = await login({ username: email, password }) as Data;

      if (data.data) {
        toast.update(toastLoading, {
          render: 'You are successfully logged in!',
          type: 'success',
          isLoading: false,
          position: 'top-center',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          bodyClassName: styles.toastBody,
          transition: flip
        });

        const { data: { token, userInfo } } = data;

        setCookie(undefined, 'nextauth.token', token, {
          maxAge: 60 * 60 * 12 // 1 hour
        });

        dispatch(setUserInfo(userInfo));
      } else {
        toast.update(toastLoading, {
          render: 'E-mail or password is incorrect!',
          type: 'error',
          isLoading: false,
          position: 'top-center',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          transition: flip
        });
      }
    } else {
      toast('Fill in the form fields!', {
        type: 'error',
        isLoading: false,
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        bodyClassName: styles.toastBody,
        transition: flip
      });
    }
  };

  const validateEmail = (email: string): boolean => {
    return !(String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) == null);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isError) {
      timer = setTimeout(() => {
        reset();
      }, 1500);
    }

    if (!isLoading && isSuccess) {
      setTimeout(() => {
        navigate(HOME);
      }, 2000);
    }

    return () => { clearTimeout(timer); };
  }, [isLoading, isSuccess, isError]);

  return (
    <main className={styles.container}>
      {/* <Head>
        <title>Login - Crud</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
      </Head> */}

      <div className={styles.welcome}>
        <div>
          <h2>Welcome</h2>
          <h3>To the dashboard</h3>
        </div>
      </div>

      <ToastContainer transition={flip} />

      <Card
        className={`${styles.loginCard} ${isSuccess ? styles.loginCardBye : ''}`}
        display='flex'
        align='center'
        width="550px              "
        padding="5rem"
      >
        <h1>Welcome</h1>
        <h2>Please, put your e-mail and password to sign-in into the <br /><strong>dashboard</strong>.</h2>

        <form
          action="#"
          method="POST"
          className={styles.loginForm}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <Input
            placeholder="E-mail"
            name="email"
            text={fields.email.value}
            onChange={({ target: { value } }) => {
              setFields(prevFields => ({
                ...prevFields,
                email: {
                  ...prevFields.email,
                  value
                }
              }));
            }}
          />
          <Input
            placeholder="Password"
            name="password"
            type="password"
            text={fields.password.value}
            onChange={({ target: { value } }) => {
              setFields(prevFields => ({
                ...prevFields,
                password: {
                  ...prevFields.password,
                  value
                }
              }));
            }}
          />
          <Button
            className={isSuccess ? styles.successLogin : ''}
            align="center"
            type="submit"
            {...(isSuccess && { status: 'success' })}
            {...(isError && !isLoading && { status: 'danger' })}
            isLoading={isLoading}
          >
            {isSuccess && (
              <FaCheckCircle />
            )}

            {isError && (
              <IoMdRemoveCircle />
            )}

            {!isSuccess && !isError && !isLoading && (
              <>
                <AiFillUnlock />
                <span>Login</span>
              </>
            )}
          </Button>
        </form>
      </Card>
    </main>
  );
};

export default LoginPage;
