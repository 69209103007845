import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User, UserState } from './types';

export const initialState: UserState = {
  userInfo: undefined
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo (state, { payload }: PayloadAction<User>) {
      state.userInfo = payload;
    }
  }
});

export const { setUserInfo } = userSlice.actions;

export default userSlice.reducer;
