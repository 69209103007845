import { routes } from "consts/routes";
import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const {
  HOME,
  ORDERS,
  ORDER_DETAIL,
  PRODUCTS,
  PRODUCT_DETAIL,
  PRODUCT_CREATE,
  CUSTOMERS,
  CUSTOMER_DETAIL
} = routes.defaults;

const HomePage = lazy(() => import('pages/HomePage/'));
const OrdersPage = lazy(() => import('pages/OrdersPage'));
const OrderPage = lazy(() => import('pages/OrderPage'));
const ProductsPage = lazy(() => import('pages/ProductsPage'));
const ProductPage = lazy(() => import('pages/ProductPage'));
const ProductsCreatePage = lazy(() => import('pages/ProductsCreatePage'));
const CustomersPage = lazy(() => import('pages/CustomersPage'));
const CustomerPage = lazy(() => import('pages/CustomerPage'));

const defaultRoutes = [
  {
    path: HOME,
    element: <HomePage />,
  },
  {
    path: ORDERS,
    element: <OrdersPage />,
  },
  {
    path: ORDER_DETAIL,
    element: <OrderPage />,
  },
  {
    path: PRODUCTS,
    element: <ProductsPage />,
  },
  {
    path: PRODUCT_DETAIL,
    element: <ProductPage />,
  },
  {
    path: PRODUCT_CREATE,
    element: <ProductsCreatePage />,
  },
  {
    path: CUSTOMERS,
    element: <CustomersPage />,
  },
  {
    path: CUSTOMER_DETAIL,
    element: <CustomerPage />,
  },
] as RouteObject[];

export { defaultRoutes };
