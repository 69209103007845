import { routes } from 'consts/routes';
import { setUserInfo } from 'features/user/userSlice';
import { parseCookies } from 'nookies';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetProfileQuery } from 'services/auth';
import { ProtectedComponentProps } from './types';

const { LOGIN } = routes.root;

const ProtectedComponent = ({
  children
}: ProtectedComponentProps): JSX.Element => {
  const dispatch = useDispatch();
  const { data, isLoading } = useGetProfileQuery('');
  const navigate = useNavigate();
  const { 'nextauth.token': token } = parseCookies();

  if (!token)
    navigate(LOGIN);
  
  useEffect(() => {
    if (!isLoading && data && token) {
      dispatch(setUserInfo(data));
    }
  }, [isLoading]);

  return <>{children}</>;
};

export default ProtectedComponent;
